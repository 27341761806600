// Generated by Framer (90417e1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./vNkVWFZJQ-0.js";

const enabledGestures = {aIkmKMIfB: {pressed: true}};

const serializationHash = "framer-PDjuD"

const variantClassNames = {aIkmKMIfB: "framer-v-sc8uzr"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {XCjGPTapi: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const numberToPixelString = (value) => {
    if (typeof value !== "number") return value;
    if (!Number.isFinite(value)) return undefined;
    return Math.max(0, value) + "px";
};


const transition1 = {delay: 0, duration: 0.2, ease: [0.44, 0, 0.56, 1], type: "tween"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, padding, width, ...props}) => { return {...props, fgIRe1pDN: image ?? props.fgIRe1pDN ?? {src: "https://framerusercontent.com/images/Xup5OwbZqDO3nEGmYzA4e1YMXiM.png?scale-down-to=512", srcSet: "https://framerusercontent.com/images/Xup5OwbZqDO3nEGmYzA4e1YMXiM.png?scale-down-to=512 512w,https://framerusercontent.com/images/Xup5OwbZqDO3nEGmYzA4e1YMXiM.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/Xup5OwbZqDO3nEGmYzA4e1YMXiM.png 1280w"}, HBeF2LLyQ: padding ?? props.HBeF2LLyQ ?? "2px"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string};padding?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, fgIRe1pDN, HBeF2LLyQ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "aIkmKMIfB", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-sc8uzr", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"aIkmKMIfB"} ref={ref ?? ref1} style={{"--1tnh5gu": numberToPixelString(HBeF2LLyQ), backgroundColor: "var(--token-7c243cc1-7048-4056-a08b-d00866b93902, rgb(255, 255, 255))", filter: "grayscale(0) hue-rotate(0deg)", WebkitFilter: "grayscale(0) hue-rotate(0deg)", ...style}} {...addPropertyOverrides({"aIkmKMIfB-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", sizes: `calc(${componentViewport?.width || "100vw"} - ${HBeF2LLyQ * 2}px)`, ...toResponsiveImage(fgIRe1pDN), ...{ positionX: "center", positionY: "center" }}} className={"framer-8g3xj8"} layoutDependency={layoutDependency} layoutId={"R1DcAZiYW"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PDjuD.framer-15c6vvz, .framer-PDjuD .framer-15c6vvz { display: block; }", ".framer-PDjuD.framer-sc8uzr { -webkit-user-select: none; align-content: flex-start; align-items: flex-start; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; height: 100px; justify-content: space-between; min-width: 100px; padding: var(--1tnh5gu); position: relative; user-select: none; width: 100px; }", ".framer-PDjuD .framer-8g3xj8 { flex: 1 0 0px; height: 1px; position: relative; width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 100
 * @framerIntrinsicWidth 100
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"],"constraints":["100px",null,null,null]},"iVit5VsJk":{"layout":["fixed","fixed"],"constraints":["100px",null,null,null]}}}
 * @framerVariables {"fgIRe1pDN":"image","HBeF2LLyQ":"padding"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramervNkVWFZJQ: React.ComponentType<Props> = withCSS(Component, css, "framer-PDjuD") as typeof Component;
export default FramervNkVWFZJQ;

FramervNkVWFZJQ.displayName = "Cubic Copy 2";

FramervNkVWFZJQ.defaultProps = {height: 100, width: 100};

addPropertyControls(FramervNkVWFZJQ, {fgIRe1pDN: {__defaultAssetReference: "data:framer/asset-reference,Xup5OwbZqDO3nEGmYzA4e1YMXiM.png?originalFilename=raiffeisen.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}, HBeF2LLyQ: {defaultValue: "2px", title: "Padding", type: ControlType.Padding}} as any)

addFonts(FramervNkVWFZJQ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})